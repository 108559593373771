import React from "react";

const FacebookIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11H19C18.2044 11 17.4413 11.3161 16.8787 11.8787C16.3161 12.4413 16 13.2044 16 14V28"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18H20"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FacebookIcon;
