import React from "react";

const InstagramIcon = (props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 23.625C21.1066 23.625 23.625 21.1066 23.625 18C23.625 14.8934 21.1066 12.375 18 12.375C14.8934 12.375 12.375 14.8934 12.375 18C12.375 21.1066 14.8934 23.625 18 23.625Z"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1875 5.0625H11.8125C8.08458 5.0625 5.0625 8.08458 5.0625 11.8125V24.1875C5.0625 27.9154 8.08458 30.9375 11.8125 30.9375H24.1875C27.9154 30.9375 30.9375 27.9154 30.9375 24.1875V11.8125C30.9375 8.08458 27.9154 5.0625 24.1875 5.0625Z"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3125 11.8125C25.9338 11.8125 26.4375 11.3088 26.4375 10.6875C26.4375 10.0662 25.9338 9.5625 25.3125 9.5625C24.6912 9.5625 24.1875 10.0662 24.1875 10.6875C24.1875 11.3088 24.6912 11.8125 25.3125 11.8125Z"
        fill="#1B4332"
      />
    </svg>
  );
};

export default InstagramIcon;
