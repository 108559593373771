import React from "react";

const ViberIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5005 3H13.4995C8.2615 3 4 7.38131 4 12.7668V17.3996C4 21.4101 6.3965 25.0145 10 26.4825V29.4858C10 29.6894 10.1165 29.8735 10.2975 29.9558C10.3625 29.9856 10.4315 30 10.5 30C10.6215 30 10.742 29.9542 10.8355 29.8668L13.735 27.1664H18.5005C23.7385 27.1664 28 22.7851 28 17.3996V12.7668C28 7.38131 23.7385 3 18.5005 3ZM27 17.3996C27 22.2179 23.187 26 18.5 26H13.4995C13.5 26.5 12.592 26.0403 12.5 26.1262L11 28.3264V26.1262C11 25.9082 10.4975 25.2282 10.2975 25.1562C6.9085 23.9238 5 21.097 5 17.3996V15V12.7668C5 7.94845 8.813 4 13.4995 4H18.5005C23.1875 4 27 7.94845 27 12.7668V17.3996Z"
        fill="#1B4332"
      />
      <path
        d="M22.9949 17.2788L19.9497 15.3922C19.6168 15.1856 19.2204 15.1157 18.8324 15.1923C18.444 15.2702 18.1101 15.4891 17.8935 15.8081L17.7503 16.0179C16.4324 15.6025 14.9792 15.0717 14.1091 12.6695L14.4693 12.3708C15.0877 11.858 15.1526 10.961 14.615 10.3702L12.2328 7.75618C12.0534 7.5591 11.7403 7.53877 11.5337 7.70939L10.0379 8.94952C8.72586 10.0375 9.33875 11.6467 9.54041 12.1756C9.54933 12.1992 9.56023 12.2219 9.57311 12.2441C9.62514 12.333 10.8673 14.4474 12.8734 16.3965C14.8875 18.3536 18.5238 20.1608 18.6353 20.2133C19.0505 20.4704 19.5177 20.5937 19.98 20.5937C20.7915 20.5937 21.5873 20.2142 22.0619 19.5186L23.1411 17.9334C23.2897 17.7136 23.2243 17.4211 22.9949 17.2788ZM21.0005 19.0002C20.5541 19.6562 19.8615 19.8456 19.1332 19.3976C19.097 19.3796 15.531 17.2425 13.627 15.3922C11.8472 13.6629 10.6615 11.9251 10.5005 11.6571C10.1081 10.6074 10.1351 10.4586 10.688 10.0002L11.7207 8.94952L13.8673 10.9903C14.0502 11.1907 14.0289 11.4837 13.8198 11.6571L13.2118 12.1609C13.0637 12.2838 13.0052 12.478 13.0632 12.6567C14.0997 15.8695 16.1153 16.5005 17.5868 16.9612L17.8014 17.0288C18.0159 17.0978 18.2508 17.0189 18.3736 16.8384L18.7249 16.3223C18.7973 16.2155 18.9083 16.1432 19.0371 16.1172C19.1664 16.0907 19.2982 16.1153 19.4102 16.1847L22.0401 17.8138L21.0005 19.0002Z"
        fill="#1B4332"
      />
    </svg>
  );
};

export default ViberIcon;
