import React from "react";

const TelegramIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 16.8588L22.2396 26.7497C22.3697 26.8641 22.5272 26.9428 22.6968 26.9781C22.8664 27.0133 23.0423 27.0039 23.2071 26.9507C23.372 26.8976 23.5202 26.8025 23.6373 26.6748C23.7544 26.5471 23.8363 26.3912 23.875 26.2224L28.5767 5.7058C28.6173 5.52841 28.6088 5.34329 28.552 5.17039C28.4952 4.9975 28.3924 4.84337 28.2544 4.72463C28.1165 4.60588 27.9488 4.52702 27.7694 4.49655C27.59 4.46607 27.4057 4.48513 27.2363 4.55167L4.1667 13.6147C3.96564 13.6937 3.79556 13.8357 3.68192 14.0194C3.56828 14.2031 3.51717 14.4187 3.53626 14.6338C3.55535 14.849 3.64359 15.0522 3.7878 15.213C3.932 15.3739 4.12441 15.4837 4.33623 15.5261L11 16.8588Z"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16.8592L28.014 4.57129"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6134 21.7987L12.7071 25.7049C12.5673 25.8448 12.3891 25.94 12.1951 25.9786C12.0011 26.0172 11.8 25.9974 11.6173 25.9217C11.4346 25.846 11.2784 25.7179 11.1685 25.5534C11.0587 25.389 11 25.1956 11 24.9978V16.8589"
        stroke="#1B4332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TelegramIcon;
